.dokuwiki {
  ul.idx {
    padding-left: @padding-large-horizontal;
  }
  a.urlextern {
    padding-left: 0;
    background-image: none;
    word-wrap: break-word;
    .glyphicon-globe;
    &:before {
      .glyphicon;
      font-size: 80%;
    }
    #IE6 & {
      background-image: none;
      padding-left: 0;
    }
  }
  div.section_highlight {
    border-right-width: 0;
    margin-left: -0.5em;
    left: -0.5em;
    position: relative;
  }
  span.curid a {
    .text-muted;
    font-weight: normal;
  }
  #login {
    display: none;
  }
}

#dokuwiki__top {
  margin-top: @navbar-height;
  .navbar-right {
    margin-right: 0;
  }
}

label span {
  margin-right: @padding-small-horizontal;
  position: relative;
  display: inline-block;
}

#helpful-links .list-group-item-text {
  margin-bottom: @padding-xs-vertical;
}

.dokuwiki ul.idx,
.notifications,
.block,
.skip-to-sidebar {
  margin-bottom: @padding-large-vertical;
}

#dokuwiki__footer,
.notifications {
  margin-top: @padding-large-vertical;
}

#dokuwiki__aside p {
  margin: 0;
}

#dokuwiki__detail div.img_detail h3 {
  margin-top: 0;
}

#dw__register,
#dw__profiledelete,
#dw__login {
  .text-left;
}

.form-horizontal {
  margin-bottom: 0;
}

.license {
  .text-muted;
  .small;
}

select,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="week"],
input[type="phone"],
input[type="email"] {
  .form-control;
  font-weight: 400;
}

img.media,
img.img_detail{
  .img-responsive();
  .img-thumbnail;
}

.page {
  h1:first-of-type {
    .page-header;
    margin-top: 20px;
  }
  img {
    max-width: 100%;
  }
}

input[type="checkbox"] + span {
  min-height: @line-height-computed; // Ensure the input doesn't jump when there is no text
  padding-left: @padding-xs-horizontal;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
#dw__recent,
#page__revisions {
  ul {
    padding-left: 0;
  }
  input[type="checkbox"] {
    top: 3px;
    position: relative;
  }
}

#dw__register input[type="submit"] {
  .btn-primary;
}

.notifications > .notify,
div.error,
div.info,
div.success,
div.notify {
  .alert;
  padding: 5px;
  padding-left: 30px;
}
div.success { .alert-success; }
div.info { .alert-info; }
div.error { .alert-danger; }
div.notify { .alert-warning; }

.leftalign,
td.leftalign { .text-left; }
.rightalign,
td.rightalign { .text-right }
.centeralign,
td.centeralign { .text-center }
.justifyalign,
td.justifyalign { .text-justify }

dt,
dd {
  padding: @padding-xs-vertical, @padding-xs-horizontal;
}

thead tr:first-child th {
  cursor: pointer;
}


@import "sidebar";
@import "buttons";
@import "editor";
@import "config";
@import "toc";
@import "media";
@import "search";
@import "ie";
