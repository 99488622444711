/* In page search - qsearch__out
 * See dropdown.less as well */
#dw__search {
  position: relative;
}
#qsearch__out {
  .dropdown-menu;
  strong {
    .dropdown-header;
  }
  ul {
    .dropdown-menu;
    display: block;
    .box-shadow(none);
    position: relative;
    border: none;

    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 0;
    margin: 0;
    > li {
      list-style: none;
    }
  }
}

