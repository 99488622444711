.toolbutton,
.button:not(.btn_secedit),
#media_manager .btn {
  .btn;
  .btn-default;
}

.toolbutton {
  .btn-sm;
}

.btn_secedit .button {
  .btn-xs;
}
