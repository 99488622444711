#tool__bar {
  .btn-group;
}

#edbtn__save {
  .btn-primary;
}

#wiki__text {
  font-family: monospace;
}

.dokuwiki textarea.edit {
  .form-control;
}

#cke_wiki__text {
  margin-bottom: @padding-small-vertical;
}

div.picker {
  button.pickerbutton,
  button.toolbutton {
    background: transparent;

    &:hover,
    &:focus {
      background: #ccc;
    }
  }
}
#picker2.picker button.pickerbutton,
#picker2.picker button.toolbutton {
    width: 20px;
}

.modal .preview {
  th:after {
    content: "Header";
  }
  td:after {
    content: "content";
  }
}

.dokuwiki #wiki__editbar {
  .editButtons,
  .summary {
    display: block;
    &:extend(.form-group);

    span {
      display: inline-block;
      max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
      margin-bottom: 5px;
    }

    #edit__summary {
      .form-control;
      font-weight: normal;
    }

    .nowrap[for="minoredit"] {
      .checkbox;
    }
  }
}
